const host = window.location.href;
let cafe_slug = host.split("/");
cafe_slug = cafe_slug[2].split(".")[0];

if (host.indexOf("localhost") != -1) {
    cafe_slug = "it-park";
}

export const api = "https://tcode.online/api/" + cafe_slug + "/";
// export const api = "https://kiosk.tcode.online/api/it-park-tubatay/";
