<template>
    <div v-if="product.id === product_id" class="productPopup__popup" @click.stop>
        <div class="productPopup__img">
            <img :src="product.image" :alt="product.real_name" />
            <div class="tagProduct hit" v-if="product.is_popular">Хит</div>
            <div class="close btn-circle" @click="$emit('isShowProduct')">
                <img :src="require(`@/assets/img/svg/close.svg`)" alt="close" />
            </div>
        </div>
        <div class="productPopup__content">
            <div class="productPopup__body">
                <div class="productPopup__head">
                    <h2 class="productPopup__title">{{ product.real_name }}</h2>
                </div>
                <div class="productPopup__descr">
                    <div class="productPopup__flex">
                        <div class="productPopup__text">
                            {{ product.description.replace(/<(.|\n)*?>/g, "") }}
                        </div>
                        <div class="info btn-circle" @click="isValues = !isValues">i</div>
                        <div class="values" v-if="isValues">
                            <div class="values__title">Пищевая ценность на 100г</div>
                            <div class="values__cols">
                                <div class="values__col">
                                    <div class="values__numb">{{ product.energy }}</div>
                                    <div class="values__name">ккал</div>
                                </div>
                                <div class="values__col">
                                    <div class="values__numb">{{ product.proteins }}</div>
                                    <div class="values__name">белки</div>
                                </div>
                                <div class="values__col">
                                    <div class="values__numb">{{ product.fat }}</div>
                                    <div class="values__name">жиры</div>
                                </div>
                                <div class="values__col">
                                    <div class="values__numb">
                                        {{ product.carbohydrates }}
                                    </div>
                                    <div class="values__name">углеводы</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="productPopup__weigth">{{ Math.round(product.weight) }} г</div>
                </div>
            </div>
            <!--/-->
            <template v-if="product.modifiers.length > 0 && !product.modifiers_as_hint">
                <div class="form_toggle">
                    <div
                        class="form_toggle-item block-flex"
                        :class="[('tab', { active: currentTab.id === modifier.id })]"
                        v-for="modifier in product.modifiers"
                        :key="modifier.id"
                    >
                        <input
                            :id="`fid-${modifier.id}`"
                            type="radio"
                            name="radio"
                            :value="modifier.name"
                            :checked="currentTab.id === modifier.id"
                            @click="$emit('handleTabClick', modifier)"
                        />
                        <label :for="`fid-${modifier.id}`">{{ modifier.name }}</label>
                    </div>
                </div>
            </template>
            <!--/-->
            <div class="recomended">
                <h3 class="recomended__title">Также рекомендуем</h3>
                <div class="products">
                    <div class="products__product" v-for="product in recommendedProducts" :key="product.id">
                        <div class="products__img">
                            <img :src="product.image" :alt="product.real_name" />
                        </div>
                        <div class="products__body">
                            <h4 class="products__title md">
                                {{
                                    product.real_name.length > 30
                                        ? product.real_name.substr(0, 30) + "..."
                                        : product.real_name
                                }}
                            </h4>
                            <h4 class="products__title mm">
                                {{
                                    product.real_name.length > 20
                                        ? product.real_name.substr(0, 20) + "..."
                                        : product.real_name
                                }}
                            </h4>
                            <!--/-->
                            <div
                                class="counter"
                                v-if="
                                    orderObj.products_ids.indexOf(product.id) != -1 &&
                                    (product.modifiers.length === 0 || product.modifiers_as_hint)
                                "
                            >
                                <button
                                    class="counter__minus"
                                    @click="$emit('changeOrderItem', orderObj.id, getOrderItem(product.id), 'decrease')"
                                >
                                    -
                                </button>
                                <span class="counter__price">{{ showPrice(product.id) }} ₽</span>
                                <button
                                    class="counter__plus"
                                    @click="$emit('changeOrderItem', orderObj.id, getOrderItem(product.id), 'increase')"
                                >
                                    +
                                </button>
                            </div>
                            <div
                                class="block-flex"
                                v-if="
                                    orderObj.products_ids.indexOf(product.id) == -1 &&
                                    (product.modifiers.length === 0 || product.modifiers_as_hint)
                                "
                            >
                                <div class="price">{{ product.price }} ₽</div>
                                <div
                                    class="btn-green add-to-cart"
                                    @click="
                                        $emit(
                                            'addToOrder',
                                            orderObj.id,
                                            product.id,
                                            product.modifiers,
                                            product.modifiers_as_hint
                                        )
                                    "
                                >
                                    +
                                </div>
                            </div>
                            <!--/-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--/-->
        <button
            v-if="orderObj.products_ids.indexOf(product.id) == -1"
            class="btn-green total-price flex-center"
            @click="$emit('modifierAdd', currentTab.id, orderObj.id, 'increase', product.id, getOrderItem(product.id))"
        >
            Купить за {{ product.price }} ₽
        </button>
        <div class="counter" v-if="orderObj.products_ids.indexOf(product.id) != -1">
            <button
                class="counter__minus"
                @click="$emit('changeOrderItem', orderObj.id, getOrderItem(product.id), 'decrease')"
            >
                -
            </button>
            <span class="counter__counts">{{ showPrice(product.id) }} ₽</span>
            <button
                class="counter__plus"
                @click="$emit('changeOrderItem', orderObj.id, getOrderItem(product.id), 'increase')"
            >
                +
            </button>
        </div>
        <!--/-->
    </div>
</template>

<script>
export default {
    name: "t-popup-product",
    props: ["product", "product_id", "showPrice", "getOrderItem", "currentTab", "recommendedProducts", "orderObj"],
    data() {
        return {
            isValues: false,
        };
    },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vars";
.productPopup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(55, 55, 55, 0.25);
    padding: 0 192px;
    @media (max-width: $ms3) {
        padding: 0;
    }
    .close {
        right: 20px;
    }
    &__popup {
        background-color: #fff;
        box-shadow: 1px 3px 25px rgba(0, 45, 29, 0.1);
        width: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 30px;
        padding: 20px;
        overflow: hidden;
    }

    &__img {
        width: 100%;
        height: 672px;
        border-radius: 26px;
        overflow: hidden;
        flex: 0 0 auto;
        position: relative;
        @media (max-width: $ms3) {
            height: 250px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: inherit;
        }
    }

    &__content {
        padding: 20px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 20px;
        @media (max-width: $ms3) {
            margin-top: 0;
            padding: 20px 0;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
        .block-counter {
            .add-to-cart {
                width: 100%;
            }
        }
        .counter {
            background: #e4f9f3;
            color: #28735f;
            &__minus,
            &__plus {
                font-weight: 700;
            }
        }
    }

    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        font-weight: 700;
        font-size: 28px;
        line-height: 38.19px;
        color: #000;
    }

    &__descr {
        margin-top: 20px;
    }
    &__flex {
        display: flex;
        justify-content: space-between;
        position: relative;
        .values {
            position: absolute;
            top: 65px;
            right: 0;
            background: #fff;
            box-shadow: 1px 3px 25px rgba(0, 45, 29, 0.1);
            border-radius: 15px;
            padding: 13.5px 21px;
            &__title,
            &__name {
                font-size: 10px;
                line-height: 13.64px;
                color: rgba(55, 55, 55, 0.5);
            }
            &__cols {
                display: flex;
            }

            &__col {
                text-align: center;
                &:not(:first-child) {
                    margin-left: 30px;
                }
            }

            &__numb {
                font-weight: 700;
                font-size: 16px;
                line-height: 21.82px;
                color: #317763;
                margin-top: 10px;
            }

            &__name {
                margin-top: 5px;
            }
        }
    }
    &__text,
    &__weigth {
        font-size: 18px;
        line-height: 24px;
        color: rgba(55, 55, 55, 0.5);
    }

    &__weigth {
        margin-top: 15px;
    }

    .info {
        margin-top: -20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 26px;
        line-height: 35.46px;
        font-weight: 700;
        color: #bebebe;
        cursor: pointer;
    }

    .tagProduct {
        position: absolute;
        top: 30px;
        left: 30px;
    }

    .recomended {
        &__title {
            font-weight: 700;
            font-size: 20px;
            line-height: 27.28px;
            color: #000;
        }
        .products {
            display: flex;
            padding: 20px 0 30px;
            overflow-y: hidden;
            @media (max-width: $ms3) {
                padding: 20px 0 10px;
            }
            .block-counter {
                justify-content: space-between;
                .add-to-cart {
                    width: 46px;
                    height: 46px;
                    border-radius: 15px;
                }
            }
            .price {
                flex: 1 1 auto;
                font-weight: 700;
                font-size: 20px;
                line-height: 27.28px;
            }
            .add-to-cart {
                width: 46px;
                height: 46px;
                border-radius: 15px;
                font-size: 20px;
                font-weight: 700;
                line-height: 27.28px;
                @media (max-width: $ms3) {
                    width: 35px;
                    height: 35px;
                    border-radius: 10px;
                }
            }
            &__product {
                display: flex;
                height: 176px;
                width: 448px;
                flex: 0 0 auto;
                box-shadow: 1px 3px 25px rgba(0, 45, 29, 0.1);
                border-radius: 20px;
                overflow: hidden;
                &:not(:first-child) {
                    margin-left: 15px;
                }
                @media (max-width: $ms3) {
                    width: 100%;
                    height: 100px;
                    border-radius: 10px;
                }
            }

            &__img {
                width: 176.1px;
                height: 176px;
                border-radius: 15px 0 0 15px;
                overflow: hidden;
                flex: 0 0 auto;
                @media (max-width: $ms3) {
                    width: 100px;
                    height: 100px;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &__body {
                padding: 30px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex: 1 1 auto;
                @media (max-width: $ms3) {
                    padding: 10px;
                }
            }

            &__title {
                font-size: 18px;
                line-height: 24px;
                color: #000;
            }
        }
    }
    .total-price {
        background-color: #28735f;
        color: #fff;
        font-weight: 700;
        font-size: 22px;
        line-height: 30.01px;
        border-radius: 20px;
        width: 100%;
        height: 64px;
        &:hover,
        &:focus {
            background-color: #e4f9f3;
            color: #28735f;
            transition: 0.2s ease 0.2s;
        }
    }
    .form_toggle {
        display: flex;
        align-items: center;
        overflow: hidden;
        background-color: #f4f4f4;
        width: 100%;
        height: 64px;
        border-radius: 10px;
        padding: 0 3px;
        margin-bottom: 30px;
    }
    .form_toggle-item {
        width: 50%;
        height: 52.8px;
        border-radius: 7px;
        font-size: 20px;
        line-height: 27.28px;
        color: #717171;
        background: none;
        margin: 0 3px;
        overflow: hidden;
    }
    .form_toggle-item input[type="radio"] {
        display: none;
    }
    .form_toggle-item label {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 34px;
        cursor: pointer;
        user-select: none;
        flex: 1 1 auto;
        height: 100%;
    }

    /* Checked */
    .form_toggle .form_toggle-item input[type="radio"]:checked + label {
        background: #fff;
        color: #317763;
        font-weight: 700;
    }
}
</style>
<style lang="scss" scoped>
@media (max-width: 767px) {
    .counter {
        height: 40px;
        &__price {
            font-size: 14px;
        }
        &__minus,
        &__plus {
            width: 40px;
            height: 40px;
        }
    }
    .productPopup .recomended .products__title {
        font-size: 14px;
        line-height: 20px;
    }
}
</style>
